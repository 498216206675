// @import url(http://db.onlinewebfonts.com/c/d365bd25dab9cb26c8171719170dfe81?family=Brandon+Text);
// @import url(http://db.onlinewebfonts.com/c/749ba57b431f4eba8b31eb57d5aab83e?family=Brandon+Text+Medium);
// @import url(http://db.onlinewebfonts.com/c/47b83f8dbd3bf93e0df5674b32c6a7e4?family=BrandonTextWeb-Regular);

/*-------BrandonTextWeb-Bold-------------*/
@font-face {
    font-family: "Brandon Text";
    src: url("../../assets/font-family/Brandon_Text.ttf") format("truetype"); /* chrome firefox opera Safari, Android, iOS 4.2+*/
    // src: url("http://db.onlinewebfonts.com/t/d365bd25dab9cb26c8171719170dfe81.eot"); /* IE9*/
    // src: url("http://db.onlinewebfonts.com/t/d365bd25dab9cb26c8171719170dfe81.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    // url("http://db.onlinewebfonts.com/t/d365bd25dab9cb26c8171719170dfe81.woff2") format("woff2"), /* chrome firefox */
    // url("http://db.onlinewebfonts.com/t/d365bd25dab9cb26c8171719170dfe81.woff") format("woff"), /* chrome firefox */
    // url("http://db.onlinewebfonts.com/t/d365bd25dab9cb26c8171719170dfe81.svg#Brandon Text") format("svg"); /* iOS 4.1- */
}

/*-------BrandonTextWeb-Medium-------------*/
@font-face {
    font-family: "Brandon Text Medium";
    // src: url("http://db.onlinewebfonts.com/t/749ba57b431f4eba8b31eb57d5aab83e.eot"); /* IE9*/
    // src: url("http://db.onlinewebfonts.com/t/749ba57b431f4eba8b31eb57d5aab83e.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    // url("http://db.onlinewebfonts.com/t/749ba57b431f4eba8b31eb57d5aab83e.woff2") format("woff2"), /* chrome firefox */
    // url("http://db.onlinewebfonts.com/t/749ba57b431f4eba8b31eb57d5aab83e.woff") format("woff"), /* chrome firefox */
    // url("http://db.onlinewebfonts.com/t/749ba57b431f4eba8b31eb57d5aab83e.svg#Brandon Text Medium") format("svg"); /* iOS 4.1- */
    src: url("../../assets/font-family/Brandon_Medium.ttf") format("truetype"); /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

/*-------BrandonTextWeb-Regular-------------*/
@font-face {
    font-family: "BrandonTextWeb-Regular";
    src: url("../../assets/font-family/Brandon_Bold.ttf") format("truetype"); /* chrome firefox opera Safari, Android, iOS 4.2+*/
    // src: url("http://db.onlinewebfonts.com/t/47b83f8dbd3bf93e0df5674b32c6a7e4.eot"); /* IE9*/
    // src: url("http://db.onlinewebfonts.com/t/47b83f8dbd3bf93e0df5674b32c6a7e4.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    // url("http://db.onlinewebfonts.com/t/47b83f8dbd3bf93e0df5674b32c6a7e4.woff2") format("woff2"), /* chrome firefox */
    // url("http://db.onlinewebfonts.com/t/47b83f8dbd3bf93e0df5674b32c6a7e4.woff") format("woff"), /* chrome firefox */
    // url("http://db.onlinewebfonts.com/t/47b83f8dbd3bf93e0df5674b32c6a7e4.svg#BrandonTextWeb-Regular") format("svg"); /* iOS 4.1- */
}

html,
body {
    font-family: "BrandonTextWeb-Regular" !important;
    height: 100%;
}

body {
    color: #232323 !important;
}

.brandon-regular {
    font-family: "BrandonTextWeb-Regular" !important;
}

.brandon-Medium {
    font-family: "Brandon Text Medium" !important;
}

.brandon-Bold {
    font-family: "Brandon Text" !important;
}

.border-separate {
    border: 1px solid #ccc;
}

.bg-pink {
    background-color: #cb007b !important;
}
.bg-light-pink {
    background-color: #fabed1 !important;
}
.pink-txt {
    color: #cb007b !important;
}

.dark-txt {
    color: #232323 !important;
}

.gray-txt {
    color: #919191 !important;
}

.darkgray-txt {
    color: #666666 !important;
}

.txt-darkgreen{
    color: #2E482B !important;
}

.outline-none {
    outline: none;
}

.h-100 {
    height: 100vh;
}

.w-170 {
    width: 170px;
}

.gray-hr,
.lightgray-border {
    border-color: #c8c7cc !important;
}

.lightgray-hr{
    border-color: #E5E5E5 !important;
}

.light-white-hr {
    border-color: #ffffff30;
}

.border-style-dashed{
    border-style: dashed !important;
}

.f-17 {
    font-size: 17px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-15 {
    font-size: 15px !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-13 {
    font-size: 13px !important;
}

.f-12 {
    font-size: 12px !important;
}

.f-11 {
    font-size: 11px !important;
}

.fw-500{
    font-weight: 500;
}

.fw-600{
    font-weight: 600;
}

.minwidth-260 {
    min-width: 260px !important;
}
.no_minwidth {
    min-width: auto !important;
}
.my_shadow {
    box-shadow: 0px 3px 24px 0px #0000000f;
}
.modal-backdrop.show {
    opacity: 0.7;
}
.border-radius-15 {
    border-radius: 15px !important;
}
.border-radius-25 {
    border-radius: 25px !important;
}
.min-width-120 {
    min-width: 120px;
}
.top-0 {
    top: 0;
}
.right-0 {
    right: 0;
}
.line-height-1_75 {
    line-height: 1.75;
}
.error_boundary_img{
    height: calc(100vh - 200px);
}
.my_custom_bg_hide{
    background-color: #8c8c8c47 !important;
}
@media screen and (min-width: 1200px) {
    .container {
        max-width: 1170px !important;
    }
}

// Gray line Button  Css start
.lightgraynoline-btn {
    border: 1px solid transparent !important;
    color: #919191 !important;
    height: 40px;
    min-width: 120px;
    transition: all 0.2s;
    font-size: 15px !important;
    font-family: "Brandon Text Medium" !important;

    &:hover {
        background: #c8c7cc;
        color: #fff !important;
        border: 1px solid #c8c7cc !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem #ededed !important;
    }
    &.min-width-120 {
        min-width: 120px;
    }
}
// Gray line Button  Css end

// Pink Button css start
.pinkbg-btn {
    border: 1px solid #cb007b !important;
    color: #fff !important;
    height: 40px;
    min-width: 160px;
    transition: all 0.2s;
    background: linear-gradient(2deg, #cb007b, #ff75ba);
    font-family: "Brandon Text Medium" !important;

    &:hover {
        background: #fff;
        color: #cb007b !important;
        border: 1px solid #fb6bb5 !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem #d00b812e !important;
    }
    &.min-width-120 {
        min-width: 120px;
    }
    &.min-width-270 {
        min-width: 270px;
    }
    &.min-height-50 {
        height: 50px;
    }
    &.shadow-light {
        box-shadow: 0px 7px 10px 0px #0000000f;
    }
}

// Pink Button css end

// Pink Only Button css start
.pink-btn {
    border: 1px solid #cb007b !important;
    color: #fff !important;
    height: 40px;
    min-width: 160px;
    transition: all 0.2s;
    background: #cb007b !important;
    font-family: "Brandon Text Medium" !important;

    &:hover {
        background: #fff !important;
        color: #cb007b !important;
        border: 1px solid #fb6bb5 !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem #d00b812e !important;
    }
    &.min-width-120 {
        min-width: 120px;
    }
    &.min-width-270 {
        min-width: 270px;
    }
    &.min-height-50 {
        height: 50px;
    }
    &.shadow-light {
        box-shadow: 0px 7px 10px 0px #0000000f;
    }
}

// Pink Button css end

// Pink Button css start
.pinkline-btn {
    border: 1px solid #cb007b !important;
    color: #cb007b !important;
    height: 40px;
    min-width: 160px;
    transition: all 0.2s;
    font-size: 15px;
    font-family: "Brandon Text Medium" !important;

    .add-icon {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: all 0.2s;
        &::after {
            content: url("../../assets/images/addicon-white.svg");
            font-size: 20px;
            position: absolute;
            opacity: 0;
            top: -6px;
            left: -15px;
            transition: all 0.2s;
            width: 14px;
        }
    }

    .edit-icon {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: all 0.2s;
        &::after {
            content: url("../../assets/images/editicon-white.svg");
            font-size: 20px;
            position: absolute;
            opacity: 0;
            top: -3px;
            left: -15px;
            transition: all 0.2s;
            width: 14px;
            background-size: 14px;
        }
    }

    &:hover {
        background: #cb007b;
        color: #fff !important;
        border: 1px solid #fb6bb5 !important;

        .add-icon,
        .edit-icon {
            padding-left: 25px;

            &::after {
                opacity: 1;
                left: 0;
            }
        }
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem #d00b812e !important;
    }
    &.min-width-120 {
        min-width: 120px;
    }
    &.min-height-50 {
        height: 50px;
    }
    &.min-width-170 {
        width: 170px;
    }
}

// Pink Button css end

// Pink CheckBox Css start
.pink-checkbox {
    .custom-control-input {
        width: 20px;
        height: 20px;
        &:checked {
            ~.custom-control-label{
                &::before {
                    border-color: #cb007b;
                    background-color: #ffffff;
                }
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.898' height='7.014' viewBox='0 0 9.898 7.014'%3E%3Cpath id='Path_411' data-name='Path 411' d='M-8.169-3.752l5.362-5.362.826.826L-8.169-2.1l-3.71-3.71.826-.826Z' transform='translate(11.879 9.114)' fill='%23cb007b'/%3E%3C/svg%3E%0A") !important;
                }
            
                &::before {
                    border-color: #919191;
                }
            }
        }
        &:focus {
            ~.custom-control-label{
                &::before {
                    box-shadow: none;
                }
            
                &:not(:checked) {
                        &::before {
                            border: 1px solid #919191;
                        }
                    
                }
            }
        }
    }
    .custom-control-input::before,
    .custom-control-input::after {
        border: 1px solid #919191;
        width: 20px;
        height: 20px;
        top: 1px;
    }
    .custom-control-input::after {
        border: none;
    }
    .custom-control-input {
        padding-left: 10px;
        cursor: pointer;
    }
}
// Pink CheckBox Css end

// pinkline CheckBox Css start
.pinkline-checkbox {
    .custom-control-input {
        &:checked {
            ~.custom-control-label{
                &::before {
                    border-color: #cb007b;
                    background-color: #ffffff;
                }
                &::after {
                    border-color: #cb007b;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.898' height='7.014' viewBox='0 0 9.898 7.014'%3E%3Cpath id='Path_411' data-name='Path 411' d='M-8.169-3.752l5.362-5.362.826.826L-8.169-2.1l-3.71-3.71.826-.826Z' transform='translate(11.879 9.114)' fill='%23cb007b'/%3E%3C/svg%3E%0A") !important;
                }
            
                &::before {
                    border-color: #919191;
                }
            }
        }
        &:focus {
            ~.custom-control-label{
                &::before {
                    box-shadow: none;
                }
            
                &:not(:checked) {
                        &::before {
                            border: 1px solid #919191;
                        }
                    
                }
            }
        }
    }
    .custom-control-label::before,
    .custom-control-label::after {
        border: 1px solid #919191;
        width: 20px;
        height: 20px;
        top: 1px;
        border-radius: 4px;
        background-color: #fff;
    }
    .custom-control-label {
        padding-left: 10px;
        cursor: pointer;
    }
}
// pinkline CheckBox Css end

// actiondropdown css start
.actiondropdown-btn {
    border: 1px solid #c8c7cc !important;
    border-radius: 6px !important;
    width: 120px;
    max-height: 40px;
    padding: 0px 25px 0px 10px !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    -webkit-appearance: none !important;
    appearance: none !important;
    outline: none;
    background-image: url("../../assets/images/down-arrow.svg");
    background-position: 85%;
    background-size: 10px;
    background-repeat: no-repeat;
    background-color: #fff !important;
    color: #232323 !important;
    box-shadow: none !important;

    &:after {
        display: none !important;
    }

    &:hover {
        background-color: #ffd5e2 !important;
        color: #cb007b !important;
        border-color: #ffd5e2 !important;
    }

    // &:after{
    //     content: '';
    //     position: absolute;
    //     width: 10px;
    //     height: 10px;
    //     right: 0;
    //     background-image:url('../../assets/images/down-arrow.svg') ;
    //     background-position: center;
    //     background-size: cover;
    // }
}

.actiondropdown-list {
    min-width: 120px !important;
    li {
        a {
            &.dropdown-item {
                padding: 0.25rem 0.85rem;
                outline: none;
                &:hover,
                &:active {
                    background: #ffd5e2;
                    color: #cb007b;
                }
            }
        }
        button {
            &.dropdown-item {
                padding: 0.25rem 0.85rem;
                outline: none;
                &:hover,
                &:active {
                    background: #ffd5e2;
                    color: #cb007b;
                }
            }
        }
    }
}

// actiondropdown css end

// selectdropdown-btn css start
select {
    &.selectdropdown-btn {
        border: 1px solid #c8c7cc;
        border-radius: 6px;
        width: 120px;
        max-height: 40px;
        padding: 0px 30px 0px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        appearance: none;
        outline: none;
        background-image: url("../../assets/images/down-arrow.svg");
        background-position: 93%;
        background-size: 10px;
        background-repeat: no-repeat;
        color: #232323;
        font-size: 15px;
    }
}
// selectdropdown-btn css end

// custom-inputbox css start
.custom-lightinputbox {
    .form-control {
        width: 100%;
        min-width: 255px;
        border-color: #c8c7cc;
        font-size: 14px;
        min-height: 40px;
        border-radius: 6px;
        font-family: "Brandon Text Medium" !important;
        &:focus {
            box-shadow: 0 0 0 0.2rem #e4e4e4;
            border-color: #919191;
        }
        &::placeholder {
            color: #c8c7cc;
            font-family: "BrandonTextWeb-Regular" !important;
        }
    }
}
// custom-drodown css end

// custom-drodown css start
.custom-drodown {
    .form-control {
        width: 100%;
        min-width: 255px;
        border-color: #919191;
        font-size: 14px;
        min-height: 40px;
        &:focus {
            box-shadow: 0 0 0 0.2rem #e4e4e4;
            border-color: #919191;
        }
        &::placeholder {
            color: #919191;
        }
    }
}
// custom-drodown css end

.input-search {
    .form-control {
        max-width: 255px;
        width: 100%;
        min-width: 255px;
        border-color: #919191;
        font-size: 14px;
        min-height: 40px;
        &:focus {
            box-shadow: 0 0 0 0.2rem #e4e4e4;
            border-color: #919191;
        }
        &::placeholder {
            color: #919191;
        }
    }
}

.main-content {
    padding: 125px 50px 25px 130px;
}

.page-heading {
    border-bottom: 1px solid #c8c7cc;
    padding-bottom: 28px;
    h4 {
        font-size: 28px;
        color: #2e482b;
        small {
            font-size: 20px;
            color: #919191;
            font-weight: 400;
        }
    }
}

.sub-heading {
    font-size: 18px;
    color: #919191 !important;
    text-decoration: none;
}

.my_custom_table {
    border-radius: 20px;
    box-shadow: 0px 1px 20px 0px #00000018;
    table {
        margin-bottom: 0px;
        &.table-main {
            tr {
                th {
                    border-top: none;
                    height: 75px;
                    vertical-align: middle;
                    font-size: 14px;
                    color: #232323;
                    padding: 0.75rem 2rem;
                }
                td {
                    height: 60px;
                    vertical-align: middle;
                    font-size: 15px;
                    color: #232323;
                    padding: 0.75rem 2rem;
                }
                &:nth-of-type(even) {
                    background-color: #fafafa;
                }
                &:nth-of-type(odd) {
                    background-color: transparent;
                }
            }
        }
    }
}

// Modal Css start
.mainmodal-wrapper {
    .modal-dialog {
        max-width: 420px;
        .modal-content {
            border: none;
            border-radius: 15px;
            .modal-header {
                height: 65px;
                padding: 20px 30px;
                .modal-title {
                    font-size: 17px;
                }
            }
            .modal-body {
                padding: 20px 30px 15px 30px;
            }
            .modal-footer {
                padding: 0px 26px 40px;
            }
        }
    }
}
// Modal Css End

// Accordion Css start
.main-accordion-wrapper {
    margin-bottom: 20px;

    .accordion-main {
        padding: 29px 50px;
        .accordion-item {
            .accordion-header {
                .accordion-title {
                    h5 {
                        font-size: 16px;
                        word-break: break-all;
                        text-transform: uppercase;
                        color: #2E482B;
                    }
                }
                .custom_edit_button {
                    border: none;
                    border-bottom: 1px solid #919191;
                    background: transparent;
                    color: #919191;
                    font-size: 13px;
                    text-transform: uppercase;
                    padding: 0;
                    outline: none;
                    // font-weight: 500;
                    font-family: "Brandon Text Medium" !important;
                }
            }
            .accordion-button .expand-button:after {
                position: absolute;
                content: "";
                width: 24px;
                height: 24px;
                right: 0px;
                background-image: url("../../assets/images/down-arrow.svg");
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                transition: ease 0.25s;
                transform: rotate(180deg);
            }
            .accordion-button.collapsed .expand-button:after {
                transform: rotate(0deg);
            }
            .accordion-collapse {
                .rs-info-block {
                    min-height: 60px;
                    h5 {
                        &.accordion-label {
                            font-size: 13px;
                            color: #919191;
                            text-transform: uppercase;
                        }
                    }
                    .form-control-inputtext {
                        padding-left: 0;
                        border: none;
                        border-bottom: 1px solid #c8c7cc;
                        border-radius: 0px;
                        box-shadow: none;
                        outline: none;
                        font-family: "Brandon Text Medium" !important;
                        &:focus {
                            border-bottom: 1px solid#CB007B;
                        }
                    }
                    .form-control-plaintext {
                        outline: none;
                        font-size: 15px;
                        font-family: "Brandon Text Medium" !important;
                    }
                }
            }
        }
    }
}
// Accordion Css end

// Custom Time Picker CSS Start
.custom-timepicker {
    .MuiFormControl-root {
        .MuiOutlinedInput-root {
            border-radius: 6px;
            .MuiOutlinedInput-input {
                padding: 10.5px 14px;
                color: #919191;
                font-size: 15px;
                &::placeholder {
                    color: #919191;
                    opacity: 1;
                    font-weight: normal;
                }
            }
            .MuiOutlinedInput-notchedOutline {
                border-color: #c8c7cc !important;
            }
            &.Mui-focus {
                .MuiOutlinedInput-notchedOutline {
                    box-shadow: 0 0 0 0.2rem #e4e4e4 !important;
                    border-color: #919191 !important;
                }
            }
            .MuiInputAdornment-root {
                .MuiIconButton-root {
                    outline: none;
                    padding: 4px;
                    color: #919191;
                }
            }
        }
    }
}
// Custom Time Picker CSS End

// TimePicker Modal Custom Design CSS Start
.MuiDialog-root {
    .MuiDialog-container {
        .MuiPickersModal-dialogRoot {
            .MuiPickersModal-dialog {
                .MuiPickersBasePicker-container {
                    .MuiPickersToolbar-toolbar {
                        background-color: #ffd5e2;
                        .MuiPickersTimePickerToolbar-hourMinuteLabel {
                            .MuiPickersToolbarButton-toolbarBtn {
                                outline: none;
                                span {
                                    &.MuiButton-label {
                                        .MuiPickersToolbarText-toolbarTxt {
                                            color: #cb007b73;
                                        }
                                        .MuiPickersToolbarText-toolbarBtnSelected {
                                            color: #cb007b;
                                        }
                                    }
                                }
                            }
                            .MuiPickersToolbarText-toolbarTxt {
                                color: #cb007b73;
                            }
                        }
                        .MuiPickersTimePickerToolbar-ampmSelection {
                            .MuiPickersToolbarButton-toolbarBtn {
                                outline: none;
                                span.MuiButton-label {
                                    .MuiPickersToolbarText-toolbarTxt {
                                        color: #cb007b73;
                                    }
                                    .MuiPickersToolbarText-toolbarBtnSelected {
                                        color: #cb007b;
                                    }
                                }
                            }
                        }
                    }
                    .MuiPickersBasePicker-pickerView {
                        .MuiPickersClock-container {
                            .MuiPickersClock-clock {
                                background-color: #ffd5e2;
                                .MuiPickersClock-pin {
                                    background-color: #cb007b;
                                }
                                .MuiPickersClockPointer-pointer {
                                    background-color: #cb007b;
                                }
                                .MuiPickersClockPointer-thumb {
                                    border-color: #cb007b;
                                }
                                .MuiPickersClockPointer-noPoint {
                                    background-color: #cb007b;
                                }
                            }
                        }
                    }
                }
            }
            .MuiDialogActions-root {
                .MuiButton-textPrimary {
                    &:nth-child(1) {
                        color: #919191;
                        outline: none !important;
                        border: 1px solid transparent;
                        border-radius: 25px;
                        transition: ease 0.25s;
                        &:hover {
                            border: 1px solid #919191;
                            background-color: #919191;
                            color: #fff;
                        }
                    }
                    &:nth-child(2) {
                        color: #cb007b;
                        outline: none !important;
                        border: 1px solid #cb007b;
                        border-radius: 25px;
                        transition: ease 0.25s;
                        &:hover {
                            border: 1px solid #cb007b;
                            background-color: #cb007b;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
// TimePicker Modal Custom Design CSS End
